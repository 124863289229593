import React, { useContext, useEffect, useState, useMemo } from "react";
import coupon from "../../Assets/Icons/discount.png"
import Modal from "../../Component/Modal/Modal";
import AlertBox from "../../Component/AlertBox/AlertBox";
import AddCouponModal from "../../Component/Modal/AddCouponModal";
import Order from "../../../API/API_SERVISES/Order";
import dlt from "../../Assets/Icons/trash.png"
import MyContext from "../../MyContext";
import axios from 'axios';
import swal from 'sweetalert';
import AlertSuccess from "../../Component/AlertBox/AlertSuccess";
import { Link, useLocation } from "react-router-dom";
import ItemMasterAPI from "../../../API/API_SERVISES/ItemMaster";
import AddAddress from "../../../API/API_SERVISES/AddAddress";
import { useNavigate } from 'react-router-dom';

const CartPage3 = (props) => {
    // const [modal, setModal] = useState(false);
    const { companyData } = useContext(MyContext);
    const [alert, setAlert] = useState(false);
    const [addcoupon, setAddCoupon] = useState(false);
    const [sweetAlert, setSweetAlert] = useState(false);
    const [addresses, setAddAddresses] = useState([]);


    const navigate = useNavigate();


    const alertbox = () => {
        setAlert(!alert)
    }

    const [visibleTables, setVisibleTables] = useState([]);

    const toggleTableVisibility = (productId) => {
        setVisibleTables(prevState => ({
            ...prevState,
            [productId]: !prevState[productId]
        }));
    };

    const OpenAddCoupon = () => {
        setAddCoupon(!addcoupon)
    }
    const { myaccountmodal, setTotalQuantity, modal, setLoginPanel, totalQuantity, signupshow,
        InputValues, setInputValues, imageBaseUrl, fetchDataWish, currentUser, wishList,
        setWishList, fetchDataCart, totalPrice, myAccount, setMyAccount, setTotalPrice, inputCartList, setInputCartList, ProducrDetailPageURL } = useContext(MyContext)

    useEffect(() => {
        fetchDataCart()
        window.scrollTo(0, 0);
    }, []);



    useEffect(() => {
        console.log("InputValues", InputValues);
    }, [InputValues]);


    const signin = () => {
        setLoginPanel(!modal)

    }
    const {
        item, setItem,

    } = props;
    const [EcomCartId, setDeleteCart] = useState(null)
    const [product, setProduct] = useState({
        id: InputValues.id,
        quantity: InputValues.quantity,
    });
    // const fetchData = async () => {
    //     // debugger;
    //     try {
    //         var loginDataString = localStorage.getItem("accountLogin"); // This is a string
    //         var loginData = JSON.parse(loginDataString);
    //         setLoginData(loginData);
    //         const val = await Order.getGetAllEcomCartDetails(loginData ? loginData.d1Id || 0 : 0);
    //         console.log(val);
    //         setInputValues(val);

    //         // let buttonClickCounts = {};


    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }

    // };
    // useEffect(() => {
    //     if (currentUser === true) {
    //         fetchData();
    //     }
    // }, [currentUser]);

    //***************************Login check ********************* */

    const fetchAddress = () => {
        const userLogin = localStorage.getItem("accountLogin");
        const userArray = userLogin ? JSON.parse(userLogin) : null;

        if (userArray) {
            // UserArray is present, navigate to "/MyAccountPage"
            // setMyAccount(!myAccount);
            navigate('/ShippingAddress');
        } else {
            // UserArray is not present, continue with your existing logic
            setMyAccount(!myAccount);
            document.body.style.overflow = myAccount ? "auto" : "hidden";
            signupshow();
        }
    };

    // **************Delete******************

    const DeleteStation = (id) => {
        // debugger
        console.log(id);
        DeleteDeleteEcomCartDetail(id);
    }
    const DeleteDeleteEcomCartDetail = async (id) => {

        try {
            const val = await Order.deleteDeleteEcomCartDetail(id);
            setDeleteCart(val)
            fetchDataCart();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
        fetchDataCart();
    };


    const [activeLink, setActiveLink] = useState("/");
    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    const [quantity1, setQuantity] = useState();
    const [loading, setLoading] = useState(false);

    const handleCartIncremrnt = async (d2AccountId, d2ItemId, id, quantity) => {
        // debugger
        setLoading(true);
        console.log(item)
        // debugger; console.log(InputValues)
        try {
            // Assuming handleChange is defined elsewhere in your code
            quantity = quantity + 1
            const updatedValues = [
                {
                    operationType: 0,
                    path: "Quantity",
                    op: "Add",
                    from: "string",
                    value: quantity.toString() // Assuming you want to increment the quantity 
                }
            ];
            // const isSuccess = await Order.UpdateEcomCartPartially(updatedValues, d2AccountId, d2ItemId, id);
            const isSuccess = await Order.UpdateEcomCartPartially(updatedValues, id);
            if (isSuccess) {
                fetchDataCart();
                console.log(wishList)

            } else {
                throw new Error("Error while updating cart");
            }
        } catch (error) {
            console.error("Error while updating cart:", error);
            fetchDataCart();

        } finally {
            setLoading(false);
        }
        fetchDataCart();
    };

    const handleCartDecrement = async (d2AccountId, d2ItemId, id, quantity) => {
        setLoading(true);
        try {
            if (quantity <= 1) {
                await DeleteDeleteEcomCartDetail(id);
            } else {
                quantity = quantity - 1
                const updatedValues = [
                    {
                        operationType: 0,
                        path: "Quantity",
                        op: "Add",
                        from: "string",
                        value: quantity.toString()
                    },
                ];
                const isSuccess = await Order.UpdateEcomCartPartially(updatedValues, id);
                if (isSuccess) {
                    fetchDataCart();
                } else {
                    throw new Error("Error while updating cart");
                }
            }
        } catch (error) {
            console.error("Error while updating cart:", error);
            fetchDataCart();
        } finally {
            setLoading(false);
        }
        fetchDataCart();
    };


    const [totalPrice2, setTotalPrice2] = useState(0);
    const [taxtotalPrice, setTaxTotalPrice] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);
    const [productPrice, setProductPrice] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsPerPage = 3; // Number of items to show per page
    const itemsPerPage2 = 1; // Number of items to show per page

    const [numItemsToShow, setNumItemsToShow] = useState(4);

    // Function to update the number of items based on the screen size
    const updateNumItemsToShow = () => {
        if (window.innerWidth < 768) {
            setNumItemsToShow(2);
        } else if (window.innerWidth < 950) {
            setNumItemsToShow(3);
        } else {
            setNumItemsToShow(4);
        }
    };

    // Add event listener to update the number of items on window resize
    useEffect(() => {
        updateNumItemsToShow();
        window.addEventListener('resize', updateNumItemsToShow);
        return () => window.removeEventListener('resize', updateNumItemsToShow);
    }, []);



    console.log(InputValues)
    console.log(wishList)

    useEffect(() => {
        fetchDataWish();
    }, [currentUser]);

    const handleNext = () => {
        if (currentIndex + itemsPerPage2 < wishList.length) {
            setCurrentIndex(currentIndex + itemsPerPage2);
        }
    };

    const handlePrev = () => {
        if (currentIndex - itemsPerPage2 >= 0) {
            setCurrentIndex(currentIndex - itemsPerPage2);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const id = window.location.href;
                const data = await ItemMasterAPI.GetItemMasterById(id);
                if (data) {
                    setItem(data);
                }
            } catch (error) {
                console.error('Error fetching item master data:', error);
            }
        };

        fetchData();
    }, []);





    return (
        <>  {InputValues.length === 0 ? (
            <div className="black-codesdf">
                <div className="p-graph-head-title">Your shopping cart is empty! </div>
                <Link to="/" className="Blank-cart-page-btn"><button className="pricebox-type-btn-theme3">Continue Shopping</button></Link>
            </div>
        ) : (
            <div style={{ width: "100%" }}>
                <div className="cartpage-high-theme3">
                    <div className="cartpage-left-theme3">
                        <div className="cartpage-heading-theme3">Cart({totalQuantity} Items)</div>
                        <div className="cartpage-reviewbox">
                            <div className="cart-container-scroll" >
                                {InputValues.map((product) => (
                                    <div className="review-box review-box-2-theme3" key={product.id}>
                                        <div className="review-box-image">
                                            <img src={`${imageBaseUrl}${product.itemImgId}`} alt="" />

                                        </div>
                                        <div className="review-box-detail">
                                            <p onClick={() => DeleteStation(product.id)} className="cartpage-2-product-remove">
                                                <i className="fa-regular fa-trash-can"></i>
                                            </p>
                                            <div className="review-box-options--theme3" style={{ paddingTop: "5px" }}>
                                                <div className="heading-panel-1-theme3">{product.itemName}

                                                </div>
                                                <div className="prize-55">
                                                {companyData.currencySymbol} {product.itemPrice}
                                                </div>
                                            </div>
                                            {/* {product.productCategory == "2" ? (
                                                <div> */}
                                            {/* <div className="review-box-options--theme3">
                                                <span className="Usage-Topic-Lc">Usage :</span>
                                                <span className="Monthly-Disposable-Lc">Monthly Disposable</span>
                                            </div> */}
                                           {product.sph_L || product.sph_L != 0 ? (
                                                <div>
                                                    <div className="Buying-For--User" onClick={() => toggleTableVisibility(product.id)}>
                                                        <span className="">Buying for
                                                            <u className="Lens-user-Mleft">Lens2cart User</u>
                                                            <i className={`fa-solid fa-angle-${visibleTables ? 'up' : 'down'}`}></i>
                                                        </span>
                                                        <span className="">No extra charge for high power</span>
                                                    </div>
                                                    {visibleTables[product.id] && (

                                                        <div className="Table-Border-Prescription ">
                                                            <table className="table-Main-contact-Lc scroll-table-ctclens">
                                                                <thead className="Table-Header-Contactlens">
                                                                    <tr>
                                                                        <th>EYE</th>
                                                                        {product.box_L && (
                                                                            <th>Boxes</th>
                                                                        )}
                                                                        {product.sph_L && (
                                                                            <th>SPH</th>
                                                                        )}
                                                                        {product.cyl_L && (
                                                                            <th>CYL</th>
                                                                        )}
                                                                        {product.axis_L && (
                                                                            <th>AXIS</th>
                                                                        )}
                                                                        {/* {product.bC_L && (
                                                                            <th>BC</th>
                                                                        )}
                                                                        {product.dia_L && (
                                                                            <th>DIA</th>
                                                                        )} */}
                                                                        {product.add_L && (
                                                                            <th>READING</th>
                                                                        )}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>L</td>
                                                                        {product.box_L && (
                                                                            <td>{product.box_L}</td>
                                                                        )}
                                                                        {product.sph_L && (
                                                                            <td>{product.sph_L}</td>
                                                                        )}
                                                                        {product.cyl_L && (
                                                                            <td>{product.cyl_L}</td>
                                                                        )}
                                                                        {product.axis_L && (
                                                                            <td>{product.axis_L}</td>
                                                                        )}
                                                                        {/* {product.bC_L && (
                                                                            <td>{product.bC_L}</td>
                                                                        )}
                                                                        {product.dia_L && (
                                                                            <td>{product.dia_L}</td>
                                                                        )} */}
                                                                        {product.add_L && (
                                                                            <td>{product.add_L}</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>R</td>
                                                                        {product.box_R && (
                                                                            <td>{product.box_R}</td>
                                                                        )}
                                                                        {product.sph_R && (
                                                                            <td>{product.sph_R}</td>
                                                                        )}
                                                                        {product.cyl_R && (
                                                                            <td>{product.cyl_R}</td>
                                                                        )}
                                                                        {product.axis_R && (
                                                                            <td>{product.axis_R}</td>
                                                                        )}
                                                                        {/* {product.bC_R && (
                                                                            <td>{product.bC_R}</td>
                                                                        )}
                                                                        {product.dia_R && (
                                                                            <td>{product.dia_R}</td>
                                                                        )} */}
                                                                        {product.add_R && (
                                                                            <td>{product.add_R}</td>
                                                                        )}

                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    )}
                                                </div>
                                            ) :("")}
                                            {/* </div>
                                            ) : null} */}
                                            {product.lensName && (
                                                <div className="review-box-options--theme3">
                                                    <div className="Lens-color-cart3page">LENS - {product.lensName}</div>
                                                    <div className="prize-55">
                                                    {companyData.currencySymbol} {product.lensPrice}
                                                    </div>
                                                </div>
                                            )}
                                            {product.coatingName && (
                                                <div className="review-box-options--theme3">
                                                    <div className="Lens-color-cart3page">COATING - {product.coatingName}</div>
                                                    <div className="prize-55">
                                                    {companyData.currencySymbol} {product.coatingPrice}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="review-box-options--theme3">
                                                <div className="Lens-color-cart3page">Final prize</div>
                                                <div className="prize-55">
                                                {companyData.currencySymbol} {(product.itemPrice + product.lensPrice + product.coatingPrice) * product.quantity}
                                                </div>
                                            </div>
                                            <p className="review-box-detail-line-4" style={{ marginBottom: "3px", paddingLeft: "5px" }}>
                                                <div className="quantity">
                                                    <a onClick={() => handleCartDecrement(product.d2AccountId, product.d2ItemId, product.id, product.quantity)} className="quantity__minus">
                                                        <span>-</span>
                                                    </a>
                                                    <input name="quantity" type="text" className="quantity__input" value={product.quantity} />
                                                    <a onClick={() => handleCartIncremrnt(product.d2AccountId, product.d2ItemId, product.id, product.quantity)} className="quantity__plus">
                                                        <span>+</span>
                                                    </a>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="cartpage-right-theme3">
                        <div className="cartpage-heading-theme3">Price Summary</div>

                        <div className="">
                            <div className="cartpage-pricebox">

                                <div className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Item Total
                                    </div>
                                    <div className="pricebox-heading-2">
                                        {companyData.currencySymbol} {totalPrice}
                                    </div>
                                </div>
                                <div style={{ color: "rgb(17, 186, 216)" }} className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Item Coupon
                                    </div>
                                    <div className="pricebox-heading-2">
                                    {companyData.currencySymbol}
                                    </div>
                                </div>
                                <div className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Net Amount
                                    </div>
                                    <div className="pricebox-heading-2">
                                    {companyData.currencySymbol} {totalPrice}
                                    </div>
                                </div>
                                <div style={{ border: '0' }} className="pricebox-type-1">
                                    <div className="pricebox-heading Total-bold-pay">
                                        Total Payable (incl. Taxes)
                                    </div>
                                    <div className="pricebox-heading-2 Total-bold-pay">
                                    {companyData.currencySymbol} {totalPrice}
                                    </div>
                                </div>
                            </div>
                            <div style={{ cursor: "pointer" }} onClick={OpenAddCoupon} className="cartpage-pricebox">
                                <div className="pricebox-1">
                                    <div style={{ display: "flex" }}>
                                        <img src={coupon} alt="" />
                                        <div className="pricebox-coupon">Add Coupon Code</div>
                                    </div>
                                    <i className="fa-solid fa-caret-right"></i>
                                </div>
                            </div>
                            <div
                                onClick={fetchAddress}
                                className={`navbar-option ${activeLink === "/MyAccount" ? "active" : ""
                                    }`}
                            >
                                <button className="pricebox-type-btn-theme3" onClick={signin} >Proccesed To CheckOut</button>
                            </div>
                        </div>

                    </div>
                    {modal && (
                        <Modal handleClick={signin} />
                    )}
                    {alert && (<AlertBox alertname="Remove Item From Cart" close={() => DeleteDeleteEcomCartDetail()} />)}
                    {addcoupon && (<AddCouponModal handleClick={OpenAddCoupon} />)}
                </div>
                <div className="cartpage-theme4">
                    <div className="wish-side-ihdds">
                        <div>
                            <h2 className="head-body-arrow">Add items from your WishList</h2>
                        </div>
                        <div>
                            <i className="fa-solid fa-arrow-left left-right-arrow" onClick={handlePrev}></i>
                            <i className="fa-solid fa-arrow-right left-right-arrow" onClick={handleNext}></i>
                        </div>
                    </div>
                    <div style={{ cursor: "pointer" }} className="cartpage-pricebox-container-theme3">
                        {wishList.slice(currentIndex, currentIndex + numItemsToShow).map((product, index) => (
                            <Link
                                to={{
                                    pathname: `${ProducrDetailPageURL}/${product.d2ItemId}`,
                                    state: { item: product }
                                }}
                                // to={`/ProductDetailTheme/${product.d2ItemId}`}
                                state={{ item: product }}
                                key={index}
                                className="pricebox-wishlist-container"
                            >
                                <div className="wishlist_background" style={{ background: `url(${imageBaseUrl}${product.itemImgId}) no-repeat center center`, backgroundSize: 'cover' }}>
                                </div>

                                <div className="title-name-wl-3">
                                    <p>{product.itemName}</p>
                                </div>
                                <div className="price-show-wl-3">
                                    <i className="fa-solid fa-indian-rupee-sign"></i> {product.itemPrice}
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>

            </div>
        )}

        </>
    );
};

export default CartPage3;
