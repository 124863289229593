import React, { useContext, useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactImageMagnify from 'react-image-magnify';
import MyContext from "../../../MyApp/MyContext";
// import './ProductSwap2.css'; // Import your custom styles if needed

const ProductSwap_LC = (props) => {
  const [backgroundPos, setBackgroundPos] = useState({ x: "0px", y: "0px" });
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [showimage, setshowimage] = useState(false)
  const { items, imageIndex} = props
  const ref = useRef()
  const boxSize = { width: 90, height: 70 };
  const { imageBaseUrl } = useContext(MyContext);

  const [enlargedImagePosition, setEnlargedImagePosition] = useState('beside');
  

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setEnlargedImagePosition('over');
      } else {
        setEnlargedImagePosition('beside');
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // var settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   // autoplay: true,
  //   // autoplaySpeed: 3000,
  // };
  // debugger

   const imageurl = `${imageBaseUrl}${items?.images[imageIndex]} `;

// const imageurl = items?.images ? [
//   `${imageBaseUrl}${items.images[imageIndex]}`,
//   `${imageBaseUrl}${items.images[selectedIndex]}`
// ] : [];


  // const imageurl2 = 'https://www.specsplus.in/shop/images/product/SNOOPY_160229_699_2.jpg';

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    // backgroundImage: `url(${imageurl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'white', // Fallback color
    zIndex: "3",
    backgroundRepeat: "no-repeat",
  };

  const handlemouseEnter = (e) => {
    debugger
    if (ref.current) {
      if (!ref.current) return;
      setshowimage(true)
      const containerRect = ref.current.getBoundingClientRect();
      const mouseX = e.clientX - containerRect.left; // Mouse X relative to container
      const mouseY = e.clientY - containerRect.top;  // Mouse Y relative to container

      // Clamp values to keep the moving-box inside the container
      const clampedX = Math.max(0, Math.min(mouseX - boxSize.width / 2, containerRect.width - boxSize.width));
      const clampedY = Math.max(0, Math.min(mouseY - boxSize.height / 2, containerRect.height - boxSize.height));

      setMousePos({ x: clampedX, y: clampedY });


      // const backgroundX = (mouseX / containerRect.top) * 100 + "%";
      // const backgroundY = (mouseY / containerRect.left) * 100 + "%";
      const backgroundX = -clampedX + 50
      const backgroundY = -clampedY + 20

      setBackgroundPos({ x: ` ${backgroundX}px`, y: `${backgroundY}px` });
    }
  }



  return (
    <>
      {/* <Slider className="product-swap-2" {...settings}> */}
      <div className="productswap-deteil-2" style={containerStyle}>
        {/* <ReactImageMagnify
          {...{
            smallImage: {

              isFluidWidth: true,
              src: imageurl,
            },
            largeImage: {
              src: imageurl,
              width: 800,
              height: 400,
            },
            style: {
              backgroundColor: 'none',

            },
            enlargedImageContainerDimensions: {
              width: "50%",
              height: '90%',
              backgroundColor: "black"
            }
            ,
            enlargedImagePosition: enlargedImagePosition,

          }}
        /> */}

        <div ref={ref} onMouseLeave={() => setshowimage(false)} style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'visible',
          boxSizing: 'border-box',
          unicodeBidi: 'isolate',
          width: '500px'
        }} onMouseMove={(e) => handlemouseEnter(e)} className={`bigimage`}>
          <img className={`pwoductswap-img`} width={'500px'} src={imageurl} />

          <div

            style={{

              boxSizing: 'border-box',
              padding: '0',
              width: boxSize.width,
              height: boxSize.height,
              margin: '0',
              display: showimage ? 'block' : 'none',
              top: `${mousePos.y}px`,
              left: `${mousePos.x}px`,
              pointerEvents: 'none'
            }}
            className={`moving-box`}
          />
        </div>


        <div style={{ display: showimage ? "block" : "none", top: '12px', backgroundPosition: `${backgroundPos.x} ${backgroundPos.y}`, backgroundImage: `url(${imageurl})` }}
          className={`smallimage`}>
        </div>

      </div >

      {/* <div className="productswap-deteil-3">
        <ReactImageMagnify
            {...{
              smallImage: {
                isFluidWidth: true,
                src: imageurl2,
              },
              largeImage: {
                src: imageurl2,
                width: 1200,
                height: 600,
              },
              style: {
                backgroundColor: 'white',
              },
              enlargedImageContainerDimensions:{
                width:  "125%",
                height : '150%',
                backgroundColor:"black"
              }
              ,
              enlargedImagePosition: 'beside', 
              
            }}
          />
        </div> */}
      {/* </Slider> */}
    </>
  );
};

export default ProductSwap_LC;
