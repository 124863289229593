import React from 'react'
import "../../../Css/Store.scss";
const Footer = () => {
    return (
        <div className="footer-container">
            {/* <div className="footer-section">
                <h1 className="footer-heading">Store Available States</h1>
                <div className="footer-list">
                    
                    <ul className="footer-list-column">
                        <li><a href="#">Andhra Pradesh</a></li>
                        <li><a href="#">Arunachal Pradesh</a></li>
                        <li><a href="#">Assam</a></li>
                        <li><a href="#">Bihar</a></li>
                        <li><a href="#">Chattisgarh</a></li>
                        <li><a href="#">Chennai</a></li>
                        <li><a href="#">Dadra and Nagar Haveli</a></li>
                    </ul>
                    <ul className="footer-list-column">
                        <li><a href="#">Delhi</a></li>
                        <li><a href="#">Goa</a></li>
                        <li><a href="#">Gujarat</a></li>
                        <li><a href="#">Gwalior</a></li>
                        <li><a href="#">Haryana</a></li>
                        <li><a href="#">Himachal Pradesh</a></li>
                        <li><a href="#">Hyderabad</a></li>
                    </ul>
                    <ul className="footer-list-column">
                        <li><a href="#">Jammu and Kashmir</a></li>
                        <li><a href="#">Jharkhand</a></li>
                        <li><a href="#">Karnataka</a></li>
                        <li><a href="#">Kerala</a></li>
                        <li><a href="#">Madhya Pradesh</a></li>
                        <li><a href="#">Maharashtra</a></li>
                        <li><a href="#">Manipur</a></li>
                        <li><a href="#">Nagaland</a></li>
                    </ul>
                    <ul className="footer-list-column">
                        <li><a href="#">New Delhi</a></li>
                        <li><a href="#">Odisha</a></li>
                        <li><a href="#">Patna</a></li>
                        <li><a href="#">Puducherry</a></li>
                        <li><a href="#">Punjab</a></li>
                        <li><a href="#">Rajasthan</a></li>
                        <li><a href="#">Sikkim</a></li>
                        <li><a href="#">Tamil Nadu</a></li>
                    </ul>
                    <ul className="footer-list-column">
                        <li><a href="#">Telangana</a></li>
                        <li><a href="#">Tripura</a></li>
                        <li><a href="#">Uttar Pradesh</a></li>
                        <li><a href="#">Uttarakhand</a></li>
                        <li><a href="#">Uttrakhand</a></li>
                        <li><a href="#">West Bengal</a></li>
                    </ul>
                </div>
            </div> */}

            <div className="footer-services">
                <div className="footer-section">
                    <h1 className="footer-heading">Our Services</h1>
                    <p className="footer-description">
                    Comprehensive Free Eye test & Examinations: At Lens2Cart, we believe in the importance of regular eye health checks, which is why we offer free eye tests conducted by our trained optometrists and eye specialists near you. Our thorough eye test ensures your vision and eye health are impeccably managed.
                    </p>
                    <p className="footer-description">
                    A Wide Range of Eyewear: Choose from the largest selection of eyeglasses, sunglasses, and contact lenses at Lens2Cart. Whether you're looking for the latest fashion trends or the best functional fit, our extensive range ensures you’ll find the perfect eyewear to suit your style and needs.
                    </p>
                    <p className="footer-description">
                    Free Repair Services: We understand that eyewear is an investment in both your vision and your appearance. That's why we offer free repair services for any wear and tear on glasses purchased from us, ensuring your eyewear remains in perfect condition.
                    </p>
                </div>
                <div className="footer-section">
                    <h1 className="footer-heading">Why Choose Us?</h1>
                    <p className="footer-description">
                    Trained Specialists and State-of-the-Art Technology: Lens2Cart sets the industry standard with highly qualified eye care professionals and cutting-edge technology in all our stores. We are committed to providing the best possible care and accurate eye health evaluations, making us the leading choice for eyewear and eye care in India.
                    </p>
                    <p className="footer-description">
                    Customer-Centric Approach: Our mission is to guarantee customer satisfaction with every visit and purchase. Lens2Cart’s team is dedicated to assisting you at every step of the way, from selecting the right frames to fitting lenses that meet your optical needs.
                    </p>
                </div>
                <div className="footer-section">
                    <div className="footer-app-download">Download the Lens2Cart App</div>
                    {/* <p className="footer-app-text">Get exclusive offers and deals</p> */}
                    <div className="footer-app-buttons">
                        <button className="footer-app-button google-play">
                            <a href="#">
                                <svg
                                    className="me-3 w-7 h-7"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="google-play"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"
                                    ></path>
                                </svg>
                                <div className="footer-app-text-content">
                                    {/* <div className="footer-app-small-text">Get in on</div> */}
                                    <div className="footer-app-large-text">Google Play</div>
                                </div>
                            </a>
                        </button>
                        <button className="footer-app-button apple-store">
                            <a href="#">

                                <svg
                                    className="me-3 w-7 h-7"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="apple"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                                    ></path>
                                </svg>
                                <div className="footer-app-text-content">
                                    {/* <div className="footer-app-small-text">Download on the</div> */}
                                    <div className="footer-app-large-text">App Store</div>
                                </div>
                            </a>
                        </button>
                    </div>
                </div>
            </div>

            {/* <div className="footer-bottom">
                © 2023 Copyright:
                <a href="https://tw-elements.com/"> {new Date().getFullYear()} Lens2Cart. All rights reserved.</a>
            </div> */}
        </div>
    )
}

export default Footer