import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import MyContext from "../../MyContext";
import ProductSearchComponent from "../ProductSlider/ProductSearchComponent";
import Order from "../../../API/API_SERVISES/Order";
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";
import AlertSuccess from "../AlertBox/AlertSuccess";
import useIntersectionObserver from "../useIntersectionObserver";

const ProductCart5_lc = (props) => {
    const { items, itemList, isVariationItmShowInCatPage } = props
    const [item, setitem] = useState(items);
    const { imageBaseUrl, fetchDataWish, setMyAccount, signupshow, myAccount, fetchDataCart, changegrid, ProducrDetailPageURL,companyData } = useContext(MyContext);
    const [variations, setvariations] = useState([]);
    const [Colors, setColors] = useState([]);


    console.log(ProducrDetailPageURL);




    const [imageSrc, setImageSrc] = useState('');

    const loadImage = async (element) => {
        const fetchVariations1 = async (d1Id) => {
            if (isVariationItmShowInCatPage == false && variations?.length == 0) {
                if (item.attributeParentId != 0) {
                    try {
                        var data = await ItemMaster.EcomItemsVariation(d1Id);
                        console.log(data);
                        setvariations(data);
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }
                }
            }
        }

        if (variations?.length == 0) {
            await fetchVariations1(item.d1Id)
        }
        if (item && item.images && item.images.length > 0) {
            setImageSrc(`${imageBaseUrl}`);
        }
    };


    useEffect(() => {
        if (variations?.length > 0) {
            var color = variations
                .flatMap(item =>
                    item.variation
                        .filter(variationItem => variationItem.attr_prnt_nm.toLowerCase() === "color" || variationItem.attr_prnt_nm.toLowerCase() === "colour") // Filter for Colour
                        .map(variationItem => variationItem.attr_nm) // Extract attr_nm
                );
            var uniqueColors = [...new Set(color)];
            setColors(uniqueColors)
        }
    }, [variations])





    const imageRef = useIntersectionObserver(loadImage, { threshold: 0.1 });

    const [hoveredImage, setHoveredImage] = useState(item?.images[0]);


    const [isImageLoaded, setIsImageLoaded] = useState(true); // Default to true for the initial image.

    useEffect(() => {
        // setHoveredImage(item.images[0])
        if (isImageLoaded) {
            imageRef.current.style.backgroundImage = `url(${imageSrc}${hoveredImage})`;
        }
    }, [hoveredImage, isImageLoaded, imageSrc]);

    useEffect(() => {
        setHoveredImage(item.images[0])
    }, [item])

    const handleMouseEnter = () => {
        if (item.images.length > 1) {
            const newImage = item.images[1];
            const img = new Image();
            img.src = `${imageSrc}${newImage}`;
            setIsImageLoaded(false);

            img.onload = () => {
                setHoveredImage(newImage);
                setIsImageLoaded(true);
            };
        }
    };

    const handleMouseLeave = () => {
        setHoveredImage(item.images[0]);
        setIsImageLoaded(true);
    };

    const [alertSuccess, setAlertSuccess] = useState(false);



    const colorchange1 = (color) => {
        var itemFind = itemList.filter(item => (item.name.includes(color)))
        if (itemFind.length > 0) {
            setitem(itemFind[0])
        }
    };

    const [, setInputValues] = useState({
        d1ItemId: item.d1ItemId,
        d2ItemId: item.d2ItemId,
        d1AccountId: 0,
        d2AccountId: 0,
        userId: item.userId,

    });

    const fetchWishList = (itemData) => {
        const userLogin = localStorage.getItem("accountLogin");
        const userArray = userLogin ? JSON.parse(userLogin) : null;

        if (userArray) {
            // UserArray is present, navigate to "/MyAccountPage"
            // setMyAccount(!myAccount);
            toggleWishlist();
        } else {
            // UserArray is not present, continue with your existing logic
            setMyAccount(!myAccount);
            document.body.style.overflow = myAccount ? "auto" : "hidden";
            signupshow();
        }
    };

    const { wishList, setWishList } = useContext(MyContext);

    const toggleWishlist = async (itemData) => {
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);

        console.log(itemData);
        console.log(item);
        console.log(wishList);
        const existingIndex = wishList.find(product => product.d1ItemId === item.d1Id);
        console.log(existingIndex);
        if (existingIndex !== undefined) {
            // alert("Item is already in wishlist");
            try {
                const val = await Order.deleteDeleteFavouriteItemList(existingIndex.id);
                if (Boolean(val) === true) {
                    // alert("Delete data successfully", "Ok");
                    fetchDataWish()
                } else {
                    alert("Error Deleting Data");
                }
                // fetchData();
            } catch (error) {
                console.error("Error deleting account:", error);
            }
            return;
        } else {

            const data = {
                d1ItemId: item.d1Id,
                d2ItemId: item.d2Id,
                d1AccountId: loginData.d1Id,
                d2AccountId: loginData.d2Id,
                userId: 1,
            };
            try {
                const isSuccess = await Order.insertCreateFavouriteItemList(data);
                if (isSuccess) {
                    // alert("Added to wishlist successfully");
                    fetchDataWish()
                } else {
                    throw new Error("Error while adding to wishlist");
                }
            } catch (error) {
                console.error("Error while adding to wishlist:", error);
                alert("Error while adding to wishlist");
            }
            setInputValues({
                d1ItemId: "",
                d2ItemId: "",
                d1AccountId: "",
                d2AccountId: "",
                userId: "",
            });
        };
    }



    const AddToCart = async () => {
        // debugger
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);
        if (loginData === null) {
            loginData = {
                d1Id: 0,
                d2Id: 0,
            }
        }

        console.log()
        console.log(item)
        const data = {
            d1ItemId: item.d1Id || item.d1ItemId,
            d2ItemId: item.d2Id || item.d2ItemId,
            d1AccountId: loginData ? (loginData.d1Id || 0) : 0,
            d2AccountId: loginData ? (loginData.d2Id || 0) : 0,
            userId: 1,
            quantity: 1,
            unit: "Pcs",
            clientId: 0,
            itemPrice: item.salePrice,
            lensPrice: item.lensPrice,
            lensName: item.lensName,
            coatingPrice: item.coatingPrice,
            coatingName: item.coatingName,

            // box_R: inputValues.boxesRight,
            // box_L: inputValues.boxesLeft,
            // sph_R: inputValues.sphRight,
            // sph_L: inputValues.sphLeft,
            // cyl_R: inputValues.cylRight,
            // cyl_L: inputValues.cylLeft,
            // axis_R: inputValues.axisRight,
            // axis_L: inputValues.axisLeft,
            // add_R: inputValues.addRight,
            // add_L: inputValues.addLeft,
            // dia_R: inputValues.diaRight,
            // dia_L: inputValues.diaLeft,
            // bC_R: inputValues.bcRight,
            // bC_L: inputValues.bcLeft,
        }
        const isSuccess = await Order.insertCreateEcomCartDetail(data);
        if (isSuccess) {
            // setTotalQuantity(isSuccess.length)
            fetchDataCart()
            setAlertSuccess(true)
            setInputValues({
                d1ItemId: " ",
                d2ItemId: " ",
                d1AccountId: " ",
                d2AccountId: " ",
                userId: " ",
                quantity: " ",
                unit: " ",
                itemPrice: " ",
                lensPrice: "",
                lensName: "",
                coatingPrice: "",
                coatingName: "",
                boxesLeft: "",
                boxesRight: "",
                sph_R: "",
                sph_L: "",
                cyl_R: "",
                cyl_L: "",
                axis_R: "",
                axis_L: "",
                add_R: "",
                add_L: "",
                dia_R: "",
                dia_L: "",
                bcRight: "",
                bcLeft: "",
            });

        }
    }
    useEffect(() => {
        if (alertSuccess) {
            const timeoutId = setTimeout(() => {
                setAlertSuccess(false);
            }, 500); // 3 seconds
            return () => clearTimeout(timeoutId);
        }
    }, [alertSuccess]);

    return (

        <div className="card-product-main-container11" style={{ width: changegrid == "Grid" && '49vw' }}>
            {alertSuccess && (<AlertSuccess />)}
            <div style={{ cursor: "pointer", position: "absolute", right: "0px", zIndex: "1" }} onClick={() => fetchWishList(item)}>
                {(wishList && wishList.find(product => product.d1ItemId === item.d1Id)) ? (
                    <i className="fa-solid fa-heart HeartSaveBtn2"></i>
                ) : (
                    <i className="fa-regular fa-heart HeartSaveBtn2 heart-cart5"></i>
                )}
            </div>
            {isVariationItmShowInCatPage == false ? (
                <div
                    className="Card-product-type-1-color-box"
                    style={{ display: changegrid === "List" || changegrid === "Grid" ? 'none' : '' }}
                >
                    {Colors.length >= 2 && Colors.map((color, index) => (
                        <div
                            key={index} // Add a unique key for each element
                            onClick={() => colorchange1(color)} // Pass the color as a prop
                            className="outline-coloroption-c2 color-option-cart5"
                        >
                            <div
                                className="color-option-box-main "
                                style={{ backgroundColor: color }} // Correct style syntax for background color
                            >
                            </div>
                        </div>
                    ))}
                </div>
            ) : ("")}

            <div className="Card-product-type-11 cart5-product-type" >
                <Link to={`${ProducrDetailPageURL}/${item.d2Id}`} state={{ item }}>
                    <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="Card-product-type-1-img"
                        ref={imageRef}
                        style={{
                            backgroundImage: `url(${imageSrc}${hoveredImage})`,
                            // transition: 'background-image 0.2s ease-in-out', // Optional: Smooth transition effect
                        }}
                    />
                    <div className="Card-product-type-1-middle" style={{ display: changegrid == "List" && 'none' }}>
                        <div className="Card-product-type-1-rating-color-box">
                            <div className="Card-product-type-1-rating-box11">
                                <div className="card-product-name" style={{ margin: "0" }}>
                                    4.7 <i className="fa-solid fa-star cart5-star"></i>
                                    21
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="Card-product-type-1-bottom">
                        <div className="Card-product-type-1-desc-box">
                            <div>
                                <p className="card-product-name Product-Name-Home">
                                    {item.sku}
                                </p>
                            </div>
                            <div>
                                {/* <p className="card-product-desc">{item.shortDescription ? item.shortDescription : "No short description found!"}</p> */}
                            </div>
                            {/* <div style={{ display: "flex", alignItems: "center" }}>
                            <p className="card-product-size11">
                                <span>Size </span>
                                Medium.Ocean Blue
                            </p>

                        </div> */}
                            <label style={{ display: "flex", alignItems: "center" }}>
                                <p className="card-product-price11 cart5-price-clr">
                                    {companyData.currencySymbol} {item.salePrice}
                                </p>
                                <p className="card-product-price-2 mrp-cart5-clr">
                                    MRP&nbsp;{companyData.currencySymbol}
                                    <span className="mrp-price">{item.mrpPrice}</span>
                                </p>
                                {/* <p className="discount-percentage">
                                    {parseInt((((item.mrpPrice - item.salePrice) / item.mrpPrice) * 100).toFixed(2))}% off
                                </p> */}
                            </label>
                        </div>
                    </div>
                    {/* <div className="Card-product-type-1-middle" style={{ display: changegrid == "List" && 'none' }}>
                        <div className="Card-product-type-1-rating-color-box">
                            <div className="Card-product-type-1-rating-box11">
                                <div className="card-product-name" style={{ margin: "0" }}>
                                    4.7 <i className="fa-solid fa-star cart5-star"></i>
                                    21
                                </div>
                            </div>
                        </div>
                        
                    </div> */}
                    <div className="Card-product-type-1-cart5">
                            Get it for {companyData.currencySymbol} 500.coupon: WELCOME
                        </div>
                </Link>
                {/* <div className="Card-product-type-1-end1">
                    <div
                        onClick={() => AddToCart(item.id)}
                        className="product-detail-button-theme3-cart-button">
                        Add To Cart{" "}
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default ProductCart5_lc;
