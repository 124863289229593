import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState, useEffect, useRef } from "react";
import "../../../Css/Store.scss";

const SearchBar = () => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsRef = useRef(null);
  const inputRef = useRef(null);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const prevScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsHeaderHidden(currentScrollY > prevScrollY.current);
      prevScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (suggestionsRef.current && selectedSuggestionIndex !== -1) {
      const selectedElement =
        suggestionsRef.current.children[selectedSuggestionIndex];
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [selectedSuggestionIndex]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedSuggestionIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedSuggestionIndex(prevIndex =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === "Enter" && selectedSuggestionIndex !== -1) {
      event.preventDefault();
      handleSelect(suggestions[selectedSuggestionIndex]);
    }
  };

  const handleChange = async event => {
    const query = event.target.value;
    setValue(query);

    if (query === "") {
      setShowSuggestions(false);
      setSuggestions([]);
      return;
    }

    try {
      const response = await fetch(
        `http://api.geonames.org/searchJSON?q=${query}&maxRows=10&username=ankush_maurya`
      );
      const data = await response.json();

      const suggestedPlaces = data.geonames.map(place => ({
        name: place.name,
        state: place.adminName1,
      }));

      setSuggestions(suggestedPlaces);
      setSelectedSuggestionIndex(-1);
      setShowSuggestions(true);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSelect = suggestion => {
    setValue(`${suggestion.name}, ${suggestion.state}`);
    setSuggestions([]);
    setSelectedSuggestionIndex(-1);
    setShowSuggestions(false);
  };

  return (
    // <div
    //   className={`flex bg-[#fbf9f7] sticky top-0 gap-[10px] py-[18px] border-b border-b-[#dbdbea] px-[80px] flex-col md:flex-row items-center p-10 `}

    // >
    //   <div className="text-center  md:text-left">
    //     <h1 className="text-[24px] leading-[36px] font-[400] font-serif    text-gray-900">
    //       Opticians in Delhi.
    //     </h1>
    //     <h2 className="  font-[400] text-[18px] font-[normal] leading-[24px] text-[#000042]">
    //       Get free eye test & eye checkup by trained optometrist and eye
    //       specialist.
    //     </h2>
    //   </div>
    //   <form className="md:ml-4 w-[450px] flex items-center justify-center  " onSubmit={e => e.preventDefault()}>
    //     {/* icon */}

    //     <div className="flex justify-end bg-white border border-[#dbdbea] rounded-[12px] px-[10px] overflow-hidden relative w-full">
    //       <div className={`h-full py-4 leading-[50px] w-auto flex items-center justify-center `}>
    //         <Icon icon="proicons:search" style={{ color: "#000042", width: '31px', height: '24px' }} />
    //       </div>
    //       <input
    //         type="text"
    //         placeholder="Search by state/pincode/Locality"
    //         className="w-full md leading-[50px] text-[17px] font-serif font-[400] shadow-sm focus:outline-none  "
    //         value={value}
    //         onChange={handleChange}
    //         onKeyDown={handleKeyDown}
    //         onFocus={() => setShowSuggestions(true)}
    //         ref={inputRef}
    //       />
    //       {/* <button
    //         type="submit"
    //         className="text-white absolute hover:bg-blue-800  focus:outline-none font-medium rounded-lg text-sm px-4 py-2 pb-4 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 bg-blue-500  shadow-md focus:ring-2 focus:ring-blue-500"
    //       >
    //         Search
    //       </button> */}
    //     </div>

    //     {showSuggestions && suggestions.length > 0 && (
    //       <div className="absolute left-0 bg-white border border-gray-300 rounded-lg overflow-y-auto max-h-40 z-20 shadow-lg w-full mt-1">
    //         <ul className="divide-y divide-gray-200">
    //           {suggestions.map((suggestion, index) => (
    //             <li
    //               key={index}
    //               onClick={() => handleSelect(suggestion)}
    //               className={`cursor-pointer hover:bg-gray-100 px-4 py-2 ${index === selectedSuggestionIndex ? "bg-gray-100" : ""
    //                 }`}
    //             >
    //               {suggestion.name}, {suggestion.state}
    //             </li>
    //           ))}
    //         </ul>
    //       </div>
    //     )}

    //     {/* ---use my country------- */}

    //   </form>
    //   <div className={`w-auto rounded-[100px] h-[48px] flex items-center justify-center bg-white border font-[16px] leading-[24px] border-[#a2a2b8] text-[#000042]`} >
    //     <div className={`w-[250px] h-full flex items-center justify-center`}>
    //       <span className={`mr-[11px]`}>
    //         <Icon icon="akar-icons:location" style={{ color: "#000042", width: '23px', height: '23px' }} />
    //       </span>
    //       <span className={` h-full flex items-center justify-center font-[700] text-[#000042] text-[16px]`}>
    //         Use my current location
    //       </span>
    //     </div>


    //   </div>

    //   <div className={` h-full flex items-center gap-[4px] justify-center`} >
    //     <span className={`w-auto h-full`}>
    //       {/* ---------whatsapp-------------- */}
    //       <Icon icon="mdi:whatsapp" style={{ color: "#000042", fontWeight: '700', lineHeight: '20px', fontSize: '12px' }} />
    //     </span>
    //     <p className={`underline font-[600] text-[#000042] text-[14px]`}>Need help?</p>
    //   </div>
    // </div>

    // <div
    //   className={`flex bg-[#fbf9f7] !sticky !top-0 gap-[10px] py-[18px] border-b border-b-[#dbdbea] px-[80px] flex-col md:flex-row items-center p-10`}
    // >
    //   <div className="text-center md:text-left">
    //     <h1 className="text-[24px] leading-[36px] font-[400] font-serif text-gray-900">
    //       Opticians in Delhi.
    //     </h1>
    //     <h2 className="font-[400] text-[18px] font-[normal] leading-[24px] text-[#000042]">
    //       Get free eye test & eye checkup by trained optometrist and eye
    //       specialist.
    //     </h2>
    //   </div>
    //   <form
    //     className="md:ml-4 w-[450px] flex items-center justify-center"
    //     onSubmit={(e) => e.preventDefault()}
    //   >
    //     {/* Search Input */}
    //     <div className="flex justify-end items-center bg-white border border-[#dbdbea] rounded-[12px] px-[10px] overflow-hidden relative w-full">
    //       <div className={`h-full py-2  leading-[50px] w-auto flex items-center justify-center`}>
    //         <Icon icon="proicons:search" style={{ color: "#000042", width: "31px", height: "24px" }} />
    //       </div>
    //       <input
    //         type="text"
    //         placeholder="Search by state/pincode/Locality"
    //         className="w-full md leading-[50px] text-[15px] font-serif font-[400] shadow-sm focus:outline-none"
    //         value={value}
    //         onChange={handleChange}
    //         onKeyDown={handleKeyDown}
    //         onFocus={() => setShowSuggestions(true)}
    //         ref={inputRef}
    //       />
    //     </div>

    //     {/* Suggestions */}
    //     {showSuggestions && suggestions.length > 0 && (
    //       <div className="absolute left-0 bg-white border border-gray-300 rounded-lg overflow-y-auto max-h-40 z-20 shadow-lg w-full mt-1">
    //         <ul className="divide-y divide-gray-200">
    //           {suggestions.map((suggestion, index) => (
    //             <li
    //               key={index}
    //               onClick={() => handleSelect(suggestion)}
    //               className={`cursor-pointer hover:bg-gray-100 px-4 py-2 ${index === selectedSuggestionIndex ? "bg-gray-100" : ""}`}
    //             >
    //               {suggestion.name}, {suggestion.state}
    //             </li>
    //           ))}
    //         </ul>
    //       </div>
    //     )}
    //   </form>

    //   {/* Use Current Location Button */}
    //   <div
    //     className={`w-auto rounded-[100px] h-[48px] flex items-center justify-center bg-white border font-[16px] leading-[24px] border-[#a2a2b8] text-[#000042]`}
    //   >
    //     <div className={`w-[250px] h-full flex items-center justify-center`}>
    //       <span className={`mr-[11px]`}>
    //         <Icon icon="akar-icons:location" style={{ color: "#000042", width: "23px", height: "23px" }} />
    //       </span>
    //       <span className={`h-full flex items-center justify-center font-[700] text-[#000042] text-[16px]`}>
    //         Use my current location
    //       </span>
    //     </div>
    //   </div>

    //   {/* WhatsApp Help */}
    //   <div className={`h-full flex items-center gap-[4px] justify-center`}>
    //     <span className={`w-auto h-full`}>
    //       <Icon icon="mdi:whatsapp" style={{ color: "#000042", fontWeight: "700", lineHeight: "20px", fontSize: "12px" }} />
    //     </span>
    //     <p className={`underline font-[600] text-[#000042] text-[14px]`}>Need help?</p>
    //   </div>
    // </div>


    <div className="header-container">
      <div className="header-title-container2">
        {/* <h1 className="header-title">
          Opticians in Delhi.
        </h1> */}
        <h2 className="header-subtitle">
          Get free eye test & eye checkup by trained optometrist and eye specialist.
        </h2>
      </div>
      <form
        className="search-form"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="search-input-container">
          <div className="search-icon">
            <Icon icon="proicons:search" style={{ color: "#000042", width: "31px", height: "24px" }} />
          </div>
          <input
            type="text"
            placeholder="Search by state/pincode/Locality"
            className="search-input"
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={() => setShowSuggestions(true)}
            ref={inputRef}
          />
        </div>

        {showSuggestions && suggestions.length > 0 && (
          <div className="suggestions-container">
            <ul>
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSelect(suggestion)}
                  className={`suggestion-item ${index === selectedSuggestionIndex ? "selected" : ""}`}
                >
                  {suggestion.name}, {suggestion.state}
                </li>
              ))}
            </ul>
          </div>
        )}
      </form>

      <div className="location-button">
        <div className="content">
          <span className="icon-location">
            <Icon icon="akar-icons:location" style={{ color: "#000042", width: "23px", height: "23px" }} />
          </span>
          <span className="text">
            Use my current location
          </span>
        </div>
      </div>

      <div className="whatsapp-help">
        <span className="whatsapp-icon">
          <Icon icon="mdi:whatsapp" style={{ color: "#000042", fontWeight: "700", lineHeight: "20px", fontSize: "12px" }} />
        </span>
        <p className="whatsapp-help-text">Need help?</p>
      </div>
    </div>

  );
};

export default SearchBar;

